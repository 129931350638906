import React from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { createContext, useState, useEffect } from 'react';
import LoginBox from '../../components/common/LoginBox';

const WishlistContext = createContext({
      totalWishlist: 0,
      wishlists: [],
      addWishlistSubmit: () => {},
      removeWishlistSubmit: () => {}
});

export function WishlistContextProvider(props) {
      const [wishlists, setWishlists] = useState([]);
      const [userWishlistCount, setUserWishlistCount] = useState(0);
      const token = JSON.parse(localStorage.getItem('token'));

      const loadData = async () => {
            if (token != null) {
                  await axios
                        .get(
                              `${process.env.REACT_APP_SECRET_KEY}/api/wishlists`,
                              {
                                    headers: {
                                          Authorization: `Bearer ${token}`
                                    }
                              }
                        )
                        .then((response) => {
                              if (response.data.result === 'success') {
                                    setUserWishlistCount(response.data.total);
                                    setWishlists(response.data.wishlists);
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
            }
      };

      useEffect(() => {
            loadData();
      }, []);

      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      const addWishlistSubmit = async (slug) => {
            if (token != null) {
                  await axios
                        .get(
                              `${process.env.REACT_APP_SECRET_KEY}/api/wishlists/${slug}`,
                              {
                                    headers: {
                                          Authorization: `Bearer ${token}`
                                    }
                              }
                        )
                        .then((response) => {
                              if (response.data.result === 'success') {
                                    loadData();
                                    toast.success(response.data.message);
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
            } else {
                  setShow(true);
            }
      };

      const removeWishlistSubmit = async (slug) => {
            if (token != null) {
                  await axios
                        .delete(
                              `${process.env.REACT_APP_SECRET_KEY}/api/wishlists/${slug}`,
                              {
                                    headers: {
                                          Authorization: `Bearer ${token}`
                                    }
                              }
                        )
                        .then((response) => {
                              loadData();
                              toast.success(response.data.message);
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
            } else {
                  setShow(true);
            }
      };

      const context = {
            totalWishlist: userWishlistCount,
            wishlists: wishlists,
            addWishlistSubmit: addWishlistSubmit,
            removeWishlistSubmit: removeWishlistSubmit
      };

      return (
            <WishlistContext.Provider value={context}>
                  <LoginBox handleClose={handleClose} show={show} />
                  {props.children}
            </WishlistContext.Provider>
      );
}

export default WishlistContext;
