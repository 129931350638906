import React, { useContext, useState } from 'react';
import LoginBox from '../../common/LoginBox';
import { Loading } from '../../styles/Loading';
import CartContext from '../../../stores/CartContext';
import { ProductDescription, WishlistButton } from './style';
import WishlistContext from '../../../stores/WishlistContext';

const ProductContent = ({ product }) => {
      const login = localStorage.getItem('isLoggedIn');
      const cartCtx = useContext(CartContext);
      const wishlistCtx = useContext(WishlistContext);

      const [num, setNum] = useState(1);

      const incNum = () => {
            if (num < 10) {
                  setNum(Number(num) + 1);
            }
      };
      const decNum = () => {
            if (num > 1) {
                  setNum(Number(num) - 1);
            }
      };

      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      const [navigate, setNavigate] = useState(false);
      const [loading, setLoading] = useState(false);

      const checkTokenExpiration = () => {
            const tokenExpiration = localStorage.getItem('tokenExpiration');
            if (!tokenExpiration || new Date(tokenExpiration) < new Date()) {
                  localStorage.removeItem('token');
                  localStorage.removeItem('isLoggedIn');
                  localStorage.removeItem('tokenExpiration');
                  handleShow();
                  setNavigate(true);
            }
      };

      const handleCart = () => {
            setLoading(true);
            setTimeout(() => {
                  if (!login) {
                        checkTokenExpiration();
                  } else {
                        cartCtx.addCartSubmit(product.slug, num);
                  }
                  setLoading(false);
            }, 2500);
      };

      const isProductInWishlist = (productSlug) => {
            console.log(wishlistCtx.wishlists);
            return wishlistCtx.wishlists.some(
                  (wishlist) => wishlist.product_slug === productSlug
            );
      };

      console.log(isProductInWishlist(product.slug));

      return (
            <>
                  {navigate && (
                        <LoginBox show={show} handleClose={handleClose} />
                  )}
                  <div className="product__content">
                        <div className="product__content--title d-flex justify-content-between align-items-center">
                              <h1>{product.title}</h1>

                              <WishlistButton
                                    className="btn"
                                    onClick={() =>
                                          wishlistCtx.addWishlistSubmit(
                                                product.slug
                                          )
                                    }
                              >
                                    <i
                                          className={`bx bx-heart ${
                                                isProductInWishlist(
                                                      product.slug
                                                )
                                                      ? 'text-danger'
                                                      : ''
                                          }`}
                                    ></i>
                              </WishlistButton>
                        </div>
                        <div className="product__content--price">
                              Rs: <span>{product.price}</span>
                        </div>
                        <hr />
                        <div className="product__meta border-0">
                              <ul className="p-0">
                                    <li className="sku__wrapper">
                                          SKU:
                                          <span className="sku">
                                                {' '}
                                                {product.product_code}
                                          </span>
                                    </li>
                                    <li className="category__wrapper">
                                          Category:
                                          <span className="category">
                                                {' '}
                                                {product.category}
                                          </span>
                                    </li>
                                    {product.size && (
                                          <li className="size__wrapper">
                                                Size:
                                                <span className="size">
                                                      {' '}
                                                      {product.size_unit}{' '}
                                                      {product.size}
                                                </span>
                                          </li>
                                    )}
                              </ul>
                        </div>
                        <ProductDescription>
                              <div
                                    dangerouslySetInnerHTML={{
                                          __html: product.description
                                    }}
                              ></div>
                        </ProductDescription>
                        <div className="mt-4 mb-0">
                              <div className="product__action mt-4 mb-0 d-flex ">
                                    <div className="product__quantity me-4">
                                          <div className="input__spinner d-flex ">
                                                <div
                                                      className="subtract"
                                                      onClick={decNum}
                                                >
                                                      <i className="bx bx-minus"></i>
                                                </div>
                                                <div className="quantity">
                                                      {num}
                                                </div>
                                                <div
                                                      className="add"
                                                      onClick={incNum}
                                                >
                                                      <i className="bx bx-plus"></i>
                                                </div>
                                          </div>
                                    </div>
                                    <div
                                          className="product__cart button-2 me-4 text-center"
                                          style={{ width: '146px' }}
                                    >
                                          <button
                                                type="button"
                                                className="btn text-uppercase text-center"
                                                onClick={() => handleCart()}
                                          >
                                                {!loading ? (
                                                      'Add To Cart'
                                                ) : (
                                                      <span
                                                            style={{
                                                                  marginLeft:
                                                                        '-0.85rem'
                                                            }}
                                                      >
                                                            <Loading />
                                                      </span>
                                                )}
                                          </button>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
};
export default ProductContent;
