import './App.css';

import '../styles/style.css';
import 'rsuite/dist/rsuite.css';
import { matchPath, useLocation } from 'react-router-dom';
import PublicRouter from '../routers/PublicRouter';
import Header from '../layouts/header';
import Footer from '../layouts/footer';

import 'react-progress-2/main.css';
import Progress from 'react-progress-2';
import { ThemeProvider } from 'styled-components';
import { lightTheme } from '../styles/components/theme';
import { GlobalStyle } from '../styles/components/globalStyle';
import ScrollToTop from '../components/ScrollToTop';
import { Suspense, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { MetaContextProvider } from '../stores/MetaContext';
import { CartContextProvider } from '../stores/CartContext';
import { WishlistContextProvider } from '../stores/WishlistContext';

function App() {
      const location = useLocation();

      let isMatchingPath = false;
      const links = ['/showrooms/:showroomId'];
      const [header, setHeader] = useState(<Header />);
      const [footer, setFooter] = useState(<Footer />);

      useEffect(() => {
            if (links) {
                  isMatchingPath = links.some(
                        (link) =>
                              !!matchPath(
                                    {
                                          path: link
                                    },
                                    location.pathname
                              )
                  );
                  if (isMatchingPath) {
                        setHeader(null);
                        setFooter(null);
                  }
            }
      }, [location.pathname]);

      return (
            <MetaContextProvider>
                  <WishlistContextProvider>
                        <CartContextProvider>
                              <div className="App">
                                    <Progress.Component
                                          style={{
                                                background: '#99999978',
                                                height: '5px'
                                          }}
                                          thumbStyle={{
                                                background: 'rgb(232, 107, 25)',
                                                height: '5px'
                                          }}
                                    />
                                    <ThemeProvider theme={lightTheme}>
                                          <GlobalStyle />
                                          <ScrollToTop />
                                          <Toaster
                                                zIndex="9999999999999999999"
                                                position="bottom-center"
                                          />
                                          {header}
                                          <Suspense fallback="Loading">
                                                <PublicRouter />
                                          </Suspense>
                                          {footer}
                                    </ThemeProvider>
                              </div>
                        </CartContextProvider>
                  </WishlistContextProvider>
            </MetaContextProvider>
      );
}

export default App;
